<template>
  <div class="abilityDetails">
    <div class="content">
      <!-- 时间 -->
      <div class="time top">
        <svg-icon icon-class="back" class="arr arr-left" @click="getDate(false)"/>
        <div class="current">{{ current }}</div>
        <svg-icon v-show="current != today" icon-class="back" class="arr arr-right"  @click="getDate(true)"/>
      </div>
      <!-- 步数 -->
      <div class="timing">
        <div class="num">总步数</div>
        <div class="number">{{ countExercise }}<span class="step">步</span></div>
      </div>
      <div class="charts">
        <div id="main" class="stepecharts"></div>
      </div>
    </div>

    <div class="content history-box">
        <!-- 时间 -->
        <div class="time">
            <!-- <svg-icon icon-class="back" /> -->
            <div class="current">历史步数</div>
        </div>
        <!-- 折线图 -->
        <div class="charts">
            <div id="historyBrokenLine" class="stepecharts"></div>
        </div>
        <HistoryBar @onIndexCahnge="onIndexCahnge"></HistoryBar>
    </div>

  </div>
</template>

<script>
import { current } from '@/util/validate'
import * as echarts from 'echarts/core'
// 引入柱状图图表，图表后缀都为 Chart
import { BarChart, LineChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import { GridComponent, TooltipComponent } from 'echarts/components'
import { GetWatchTypeByDate, GetWatchTypeByHistoryDate } from '@/api/index'
import { mapGetters } from 'vuex'
import HistoryBar from '../../components/tabbar/HistoryBar.vue'

// 注册必须的组件
echarts.use([GridComponent, BarChart, LineChart, CanvasRenderer, TooltipComponent])
export default {
  name: 'Step',
  components: {
    HistoryBar
  },
  computed: {
    ...mapGetters([
      'userid'
    ])
  },
  data () {
    return {
      current: current(),
      countExercise: 0,
      xData: [],
      YData: [],
      currentActiveIndex:0,
      historyXData:[],
      historyYData:[],
    }
  },
  mounted () {
    this.getStepData()
    this.getHistoryStepData()
  },
  methods: {
    getStepData(){
      GetWatchTypeByDate({
        userId: this.userid,
        time: this.current,
        type: 6// 查询类型 1心率 2血压 3血氧 4睡眠 5压力 6步数
      }).then(res => {
        const _data = res.data
        this.countExercise = _data.countExercise
        this.xData = _data.list[0].time
        this.yData = _data.list[0].value
        this.$nextTick(() => {
          this.stepConfig()
        })
      }).catch(err=>{
        this.xData = []
        this.yData = []
        this.$nextTick(() => {
          this.stepConfig()
        })
      })
    },
    // 步数 柱状图
    stepConfig () {
      // 基于准备好的dom，初始化echarts实例
      var chartDom = document.getElementById('main')
      var myChart = echarts.init(chartDom)
      var option // 配置项
      option = {
        grid: {
          left: '3%',
          right: '7%',
          bottom: '3%',
          containLabel: true
        },
        color: ['#56D4D2'],
        xAxis: {
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          type: 'category',
          data: this.xData,
          axisLabel: {
            showMinLabel: true,
            showMaxLabel: true,
            interval: Math.ceil(this.xData.length / 4),
            margin:20
          }
        },

        yAxis: {
          type: 'value',
          min: 0,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: [
          {
            data: this.yData,
            type: 'bar'
          }
        ]
      }
      option && myChart.setOption(option)
    },
    //获取历史数据
    getHistoryStepData(){
      GetWatchTypeByHistoryDate({
        userId: this.userid,
        timeType: this.currentActiveIndex+1,//1近一周 2近一个月 3近三个月 4近半年 5近一年
        dateTtpe: 6// 查询类型 1心率 2血压 3血氧 4睡眠 5压力 6步数
      }).then(res => {
        const _data = res.data
        // console.log('------',_data)
        let _list = _data.daysList.reverse()
        this.historyXData = _list.map(item=>{
            return item.time;
        })
        this.historyYData = _list.map(item=>{
            return item.value;
        })
        this.$nextTick(() => {
            this.initHistoryBrokenLine()
        })
      })
    },

    initHistoryBrokenLine(){
      if (!document.getElementById('historyBrokenLine')) {
        return
      }
      let chartDom = document.getElementById('historyBrokenLine')
      let myChart = echarts.init(chartDom)
      let option
      option = {
        tooltip: {
            trigger: 'axis',
        },
        xAxis: {
          type: 'category',
          data: this.historyXData,
        //   nameLocation: "end",
          boundaryGap: false,
          axisLine: {
            show: false // 是否显示坐标轴
          },
          axisTick: {
            show: false // 是否显示坐标轴刻度
          },
          splitLine: { show: false },// 去除网格线
          axisLabel: {
            showMinLabel: true,
            showMaxLabel: true,
            margin:'20',
            formatter:(v, i)=> {
                return this.currentActiveIndex == 0 || this.currentActiveIndex == 1? v.substring(5):v
            },
          }
        },
        yAxis: {
          type: 'value',
          scale: true,
          // max : 100,
          min: 0,
          splitNumber: 3,
        },
        grid: {
          left: "10%",
          right: "7%",
          bottom:"20%"
        },
        series: [
          {
            type: 'line',
            symbol: 'none',
            sampling: 'lttb',
            itemStyle: {
              color: '#FF9281'
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#FF9180'
                },
                {
                  offset: 1,
                  color: '#FFC9C0'
                }
              ])
            },
            data: this.historyYData
          }
        ]
      }
      option && myChart.setOption(option)
    },
    
    onIndexCahnge(index){
      this.currentActiveIndex = index
      this.getHistoryStepData()
    },
    getDate(flag){
      let t = flag? new Date(this.current).getTime()+86400000: new Date(this.current).getTime()-86400000
      this.current = current(new Date(t))
      this.getStepData()
    },
  }
}
</script>

<style lang='scss' scoped>
.abilityDetails {
  // height: 100%;
  padding: 0 20px;
  background: url('https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/modularBag7.png') no-repeat;
  background-size: 100%;
  background-color: #f7f7f7;
  .content {
    width: 100%;
    border-radius: 15px;
    background: #fff;
    // height: 80%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .time {
            color: #868686;
            display: flex;
            align-items: center;
            font-size: 16px;
            position: relative;
            .arr-left{
              position: absolute;
              top:50%;
              transform: translate(0, -50%);
              left:-10px;
            }
            .arr-right{
              position: absolute;
              top:50%;
              transform: translate(0, -50%) rotate(180deg);
              right:-10px;
            }
            &.top{
              margin-bottom: 20px;
              display: flex;
              align-content: center;
            }
            .current {
                font-size: 20px;
                font-weight: 700;
                margin: 0 10px;
            }
        }
    .timing {
      align-items: center;
      font-size:20px;
      .num {
        margin: 20px 0;
        text-align: center;
        color: #868686;
        font-size: 25px;
      }
      .number {
        font-size: 30px;
      }
      .step {
        font-size: 25px;
        color: #868686;
      }
    }
    .charts {
      width:100%;
      position: relative;
      .stepecharts {
        // width: 400px;
        width:100%;
        height: 280px;
      }
      .timenode {
          width: 90%;
          display: flex;
          justify-content: space-between;
          position: absolute;
          bottom: 0;
          right: 0;
      }

    }
  }
  .content1 {
    margin-top: 10px;
    width: 100%;
    border-radius: 15px;
    background: #fff;
    padding: 15px;
    .history {
      font-size: 22px;
      color: #4f4f4f;
    }
    .historyecharts {
      width: 400px;
      height: 280px;
    }
    .near {
      display: flex;
      justify-content: space-evenly;
      margin-top: 25px;
      font-size: 15px;
      .times {
        width: 60px;
        padding: 8px;
        color: #868686;
      }
      .times1 {
        border-radius: 20px;
        text-align: center;
        background: #ffe6e2;
        color: #ff9180;
      }
    }
  }
}
</style>
